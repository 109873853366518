module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Glymph","short_name":"David G","start_url":"/","background_color":"#ffffff","icon":"src/assets/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b4a64141f405e37d532a19eb82bc14e4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"C:/Users/David Glymph/Desktop/coding/gatsby-test/david-glymph/node_modules/gatsby-remark-images","id":"02f8c447-ea25-5b76-8367-f8e331032ba5","name":"gatsby-remark-images","version":"6.15.0","modulePath":"C:\\Users\\David Glymph\\Desktop\\coding\\gatsby-test\\david-glymph\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[],"linkImagesToOriginal":false,"maxWidth":"672px","backgroundColor":"transparent"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"C:/Users/David Glymph/Desktop/coding/gatsby-test/david-glymph/node_modules/gatsby-remark-autolink-headers","id":"84411983-3572-54e6-8782-dedc5abaf1a7","name":"gatsby-remark-autolink-headers","version":"5.15.0","modulePath":"C:\\Users\\David Glymph\\Desktop\\coding\\gatsby-test\\david-glymph\\node_modules\\gatsby-remark-autolink-headers\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"C:/Users/David Glymph/Desktop/coding/gatsby-test/david-glymph/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.15.0","modulePath":"C:\\Users\\David Glymph\\Desktop\\coding\\gatsby-test\\david-glymph\\node_modules\\gatsby-remark-copy-linked-files\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\David Glymph\\Desktop\\coding\\gatsby-test\\david-glymph","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":"672px","backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
